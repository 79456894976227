import layerBuilder from "./layerBuilder";
import { commonTextSize, defaultIconSize, SELECTED_STATE, STATE_PROP } from "./commonProps";
import { PartialLayerSpec } from "../classes/map/LayerTypes";

const BACKGROUND_STATE = "background";

const searchTextLayerSpecs: PartialLayerSpec = {
    type: "symbol",
    layout: {
        "text-field": ["get", "title"],
        "text-anchor": "top",
        "text-offset": [0, 0.5],
        "text-font": ["Noto-Bold"],
        "text-size": commonTextSize as number,
        "text-padding": 5
    },
    paint: {
        "text-color": "#333333",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 1.5],
        "text-translate-anchor": "viewport",
        "text-opacity": ["case", ["==", ["get", STATE_PROP], SELECTED_STATE], 0, 1]
    }
};

const searchIconLayerSpecs: PartialLayerSpec = {
    type: "symbol",
    layout: {
        "icon-image": [
            "case",
            ["==", ["get", STATE_PROP], BACKGROUND_STATE],
            ["get", "iconID"],
            ["concat", ["get", "iconID"], "_pin"]
        ],
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-padding": 0,
        "icon-size": defaultIconSize as number
    },
    paint: {
        "icon-translate": [0, 5],
        "icon-translate-anchor": "viewport",
        "icon-opacity": ["case", ["==", ["get", STATE_PROP], SELECTED_STATE], 0, 1]
    }
};

const searchHoverLayerSpecs: PartialLayerSpec = {
    type: "symbol",
    layout: {
        "icon-image": ["concat", ["get", "iconID"], "_pin"],
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-padding": 0,
        "text-field": ["get", "title"],
        "text-anchor": "top",
        "text-offset": [0, 0.5],
        "text-font": ["Noto-Bold"],
        "text-size": commonTextSize as number,
        "text-padding": 5,
        "text-allow-overlap": true
    },
    paint: {
        "icon-translate-anchor": "viewport",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 1.5],
        "text-translate-anchor": "viewport",
        "icon-translate": [0, 5],
        "text-color": "#3788BD",
        "icon-opacity": 1,
        "text-opacity": 1
    }
};

export const searchResultTextPointsLayer = layerBuilder(searchTextLayerSpecs);

export const searchResultIconPointsLayer = layerBuilder(searchIconLayerSpecs);

export const hoveredSearchResultLayer = layerBuilder(searchHoverLayerSpecs);

export const selectedSearchPinLayer = layerBuilder({
    ...searchHoverLayerSpecs,
    layout: {
        ...searchHoverLayerSpecs.layout,
        "icon-image": "default_pin"
    },
    paint: {
        ...searchHoverLayerSpecs.paint,
        "icon-translate": [0, 6]
    }
});

export const contextMenuPinLayer = layerBuilder({
    type: "symbol",
    layout: {
        "icon-image": "selection_pin",
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-size": 0.5,
        "icon-padding": 0
    },
    paint: {
        "icon-translate": [0, 5],
        "icon-translate-anchor": "viewport"
    }
});
