import axios from "axios";
import {
    DiscoverParameters,
    DiscoverResponse,
    SuggestParameters,
    SuggestResponse,
    DetailsParameters,
    DetailsResponse
} from "./ngsAPITypes";
import queryString from "query-string";

let ngsBaseURL = "";
let apiKey = "";

export function suggest(parameters: SuggestParameters): Promise<SuggestResponse> {
    let ngsUrl = `${ngsBaseURL}/suggest?${stringify(parameters)}&key=${apiKey}`;
    const viewport = parameters.viewport;
    if (viewport) {
        const newLink = new URLSearchParams(ngsUrl);
        newLink.delete("viewport");
        newLink.append("viewport", `${viewport[1][1]},${viewport[0][0]},${viewport[0][1]},${viewport[1][0]}`);
        ngsUrl = decodeURIComponent(newLink.toString());
    }
    return axios.get<SuggestResponse>(ngsUrl).then((result) => result.data);
}

export function discover(parameters: DiscoverParameters): Promise<DiscoverResponse> {
    const ngsUrl = `${ngsBaseURL}${parameters.link}&key=${apiKey}`;
    return axios.get<DiscoverResponse>(ngsUrl).then((result) => result.data);
}

export function details(parameters: DetailsParameters): Promise<DetailsResponse> {
    const ngsUrl = `${ngsBaseURL}/details?${stringify(parameters)}&key=${apiKey}`;
    return axios.get<DetailsResponse>(ngsUrl).then((result) => result.data);
}

export function detailsFromLink(link: string): Promise<DetailsResponse> {
    const ngsUrl = `${ngsBaseURL}${link}&key=${apiKey}`;
    return axios.get<DetailsResponse>(ngsUrl).then((result) => result.data);
}

const stringify = (parameters) => queryString.stringify(parameters, { encode: true });

type Configuration = {
    baseURL: string;
    apiKey: string;
};

export function configure(configuration: Partial<Configuration>) {
    ngsBaseURL = configuration.baseURL || ngsBaseURL;
    apiKey = configuration.apiKey || apiKey;
}
