export type SuggestParameters = {
    query: string;
    position: string;
    types?: string;
    limit?: number;
    language?: string;
    viewport?: [[number, number], [number, number]];
};

export enum ResultType {
    POI = "POI",
    STREET = "STREET",
    CROSS_STREET = "CROSS_STREET",
    GEOGRAPHY = "GEOGRAPHY",
    POINT_ADDRESS = "POINT_ADDRESS",
    ADDRESS_RANGE = "ADDRESS_RANGE",
    BRAND = "BRAND",
    CATEGORY = "CATEGORY"
}

export type SuggestResponse = {
    results: SuggestResult[];
};

const searchTypes: ResultType[] = [
    ResultType.POI,
    ResultType.STREET,
    ResultType.CROSS_STREET,
    ResultType.GEOGRAPHY,
    ResultType.POINT_ADDRESS,
    ResultType.ADDRESS_RANGE
];

const autocompleteTypes: ResultType[] = [ResultType.BRAND, ResultType.CATEGORY];

export function isSearchType(result: ResultType): boolean {
    return searchTypes.includes(result);
}

export function isAutocompleteType(result: ResultType): boolean {
    return autocompleteTypes.includes(result);
}

export type SuggestResult = {
    type: ResultType;
    id?: string;
    name: string;
    description?: string;
    more: {
        discover?: string;
        details?: string;
    };
    categories?: [
        {
            id: number;
        }
    ];
}

export type DiscoverResponse = {
    results: DiscoverResult[];
};

export type DiscoverResult = {
    id: string;
    name: string;
    type: ResultType;
    description: string;
    position: Position;
    categories?: Category[];
    more: {
        discover?: string;
        details?: string;
    };
}

type Position = {
    lat: number;
    lon: number;
}

type Category = {
    id: number;
    code: string;
    names: [
        {
            nameLocale: string,
            name: string
        }
    ]
}

export type DiscoverParameters = {
    link: string;
};

export type DetailsParameters = {
    id: string;
    language?: string;
};

export type DetailsResponse = {
    id: string;
    name: string;
    description: string;
    type: ResultType;
    contact?: {
        phone: string;
        url: string;
    };
    categories: Category[];
    brands?: [
        {
            name: string;
        }
    ];
    address?: {
        streetNumber?: string;
        streetName?: string;
        municipalitySubdivision?: string;
        municipality?: string;
        countrySecondarySubdivision?: string;
        countryTertiarySubdivision?: string;
        countrySubdivision?: string;
        postalCode?: string;
        extendedPostalCode?: string;
        countryCode?: string;
        country?: string;
        countryCodeISO3?: string;
        freeformAddress?: string;
        countrySubdivisionName?: string;
        localName?: string;
    };
    openingHours?: {
        mode: string;
        timeRanges: [
            {
                startTime: {
                    date: string;
                    hour: number;
                    minute: number;
                };
                endTime: {
                    date: string;
                    hour: number;
                    minute: number;
                };
            }
        ];
    };
    timeZone?: {
        ianaId: string;
    };
    mapcodes?: [
        {
            type: string;
            code: string;
            fullMapcode?: string;
            territory?: string;
        }
    ];
    geometries: {
        position: Position;
        viewport?: {
            topLeftPosition: Position;
            bottomRightPosition: Position;
        };
        boundingBox?: {
            topLeftPosition: Position;
            bottomRightPosition: Position;
        };
        entryPoints?: [
            {
                type: string;
                position: Position;
            }
        ];
    };
    chargingPark?: {
        connectors: [
            {
                connectorType: string;
                ratedPowerKW: number;
                voltageV: number;
                currentA: number;
                currentType: number;
            }
        ];
    };
    dataSources?: {
        chargingAvailability?: {
            id: string;
        };
        geometry?: {
            id: string;
        };
        parkingAvailability?: {
            id: string;
        };
        fuelPrice?: {
            id: string;
        };
    };
};
