import layerBuilder from "./layerBuilder";

const color = "#0A3653";

export const locationGeometryFill = layerBuilder({
    type: "fill",
    paint: {
        "fill-color": color,
        "fill-opacity": 0.15,
        "fill-antialias": false
    }
});

export const locationGeometryOutline = layerBuilder({
    type: "line",
    paint: {
        "line-color": color,
        "line-opacity": 0.45,
        "line-width": 2
    }
});
