import layerBuilder from "./layerBuilder";
import { commonTextSize, SELECTED_STATE, STATE_PROP } from "./commonProps";

export const activeDestinationLayer = layerBuilder({
    type: "symbol",
    layout: {
        "icon-image": "waypoint_finish",
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-padding": 0,
        "icon-size": ["case", ["==", ["get", STATE_PROP], SELECTED_STATE], 0.5, 0.425],
        "text-optional": true,
        "text-field": ["get", "title"],
        "text-anchor": "top",
        "text-offset": [0, 0.5],
        "text-font": ["Noto-Bold"],
        "text-size": commonTextSize,
        "text-padding": 5
    },
    paint: {
        "icon-translate": [0, 5],
        "icon-translate-anchor": "viewport",
        "text-color": "#333333",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 1.5],
        "text-translate-anchor": "viewport"
    }
});
