import { LayerSpecification } from "maplibre-gl";
import { LayerConfig, PartialLayerSpec } from "../classes/map/LayerTypes";

const layerBuilder =
    (partialLayer: PartialLayerSpec) =>
    (layerId: string, beforeId?: string): LayerConfig => ({
        layer: {
            ...partialLayer,
            id: layerId
        } as LayerSpecification,
        ...(beforeId && { beforeId })
    });

export default layerBuilder;
