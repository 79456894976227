import { DataDrivenPropertyValueSpecification } from "maplibre-gl";
import layerBuilder from "./layerBuilder";
import { PartialLayerSpec } from "../classes/map/LayerTypes";
import { FOREGROUND_COLOR } from "./routeLayers";

const MAIN_LAYER_ID = "mainlayer";
const OUTLINE_COLOR = "rgba(255,255,255,0.7)";
const SS_COLOR = "#1BB066";
const LINE_WIDTH: DataDrivenPropertyValueSpecification<number> = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    1,
    5,
    2,
    10,
    3,
    18,
    5
];

const OUTLINE_WIDTH: DataDrivenPropertyValueSpecification<number> = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    3,
    5,
    4,
    10,
    6,
    18,
    9
];

const publishedRouteLineLayerSpec: PartialLayerSpec = {
    type: "line",
    "source-layer": MAIN_LAYER_ID,
    layout: {
        "line-cap": "round",
        "line-join": "round"
    }
};

export const scenicSegmentLine = layerBuilder({
    ...publishedRouteLineLayerSpec,
    paint: {
        "line-color": SS_COLOR,
        "line-width": LINE_WIDTH
    }
});

export const publishedRouteOutline = layerBuilder({
    ...publishedRouteLineLayerSpec,
    paint: {
        "line-color": OUTLINE_COLOR,
        "line-width": OUTLINE_WIDTH
    }
});

export const publishedRoute = layerBuilder({
    ...publishedRouteLineLayerSpec,
    paint: {
        "line-color": FOREGROUND_COLOR,
        "line-width": LINE_WIDTH
    }
});

export const publishedRiderRoute = layerBuilder({
    ...publishedRouteLineLayerSpec,
    paint: {
        "line-color": "#0568A8",
        "line-width": LINE_WIDTH
    }
});
