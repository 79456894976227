import layerBuilder from "./layerBuilder";
import { FINISH_INDEX, MIDDLE_INDEX, START_INDEX } from "../utils/waypoint";
import { BACKGROUND_STATE, commonTextSize, HOVERED_STATE, MAP_FONT, SELECTED_STATE, STATE_PROP } from "./commonProps";
import { FOREGROUND_COLOR } from "./routeLayers";

export const planRouteWaypoints = layerBuilder({
    type: "symbol",
    paint: {
        "text-color": "#ffffff"
    },
    // prettier-ignore
    layout: {
        "symbol-sort-key":
            ["case",
                ["==", ["get", "type"], "SOFT"], 0,
                ["abs", ["-", ["get", "index"], 1000]]],
        // centered text to indicate stop numbers (1, 2 ...):
        "text-field": [
            "case",
            ["any", ["==", ["get", "type"], "SOFT"],
                ["in", ["get", "indexType"], ["literal", [START_INDEX, FINISH_INDEX]]],
                ["==", ["get", "isChargingStop"], true]],
            "",
            // else
            ["get", "plannerInputIndex"]
        ],
        "text-font": [MAP_FONT],
        "text-size": [
            "case",
            ["==", ["get", STATE_PROP], SELECTED_STATE], 15,
            ["==", ["get", STATE_PROP], HOVERED_STATE], 15,
            13
        ],
        "icon-image": [
            "case",
            ["==", ["get", "type"], "SOFT"], "waypoint_soft",
            ["all", ["==", ["get", "isChargingStop"], true], ["==", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_charging_point_background",
            ["all", ["==", ["get", "isChargingStop"], true], ["!=", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_charging_point",
            ["all", ["==", ["get", "indexType"], START_INDEX], ["==", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_start_background",
            ["all", ["==", ["get", "indexType"], START_INDEX], ["!=", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_start",
            ["all", ["==", ["get", "indexType"], FINISH_INDEX], ["==", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_finish_background",
            ["all", ["==", ["get", "indexType"], FINISH_INDEX], ["!=", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_finish",
            ["all", ["==", ["get", "indexType"], MIDDLE_INDEX], ["==", ["get", STATE_PROP], BACKGROUND_STATE]],
            "waypoint_stop_background",
            "waypoint_stop"
        ],
        // pin vs circle:
        "icon-anchor": [
            "case",
            ["==", ["get", "indexType"], MIDDLE_INDEX], "center",
            // else
            "bottom"
        ],
        "icon-size": [
            "case",
            ["==", ["get", STATE_PROP], HOVERED_STATE], 0.5,
            ["==", ["get", STATE_PROP], SELECTED_STATE], 0.5,
            0.425
        ],
        "text-allow-overlap": true,
        "icon-allow-overlap": true
    }
});

export const planRouteWaypointLabels = layerBuilder({
    type: "symbol",
    paint: {
        "text-color": [
            "case",
            ["==", ["get", STATE_PROP], HOVERED_STATE],
            FOREGROUND_COLOR,
            ["==", ["get", STATE_PROP], SELECTED_STATE],
            FOREGROUND_COLOR,
            "black"
        ],
        "text-halo-width": 1.5,
        "text-halo-color": "#ffffff"
    },
    layout: {
        "text-field": ["get", "title"],
        "text-anchor": "top",
        "text-font": [MAP_FONT],
        "text-size": commonTextSize,
        // pin vs circle:
        "text-offset": [
            "case",
            ["==", ["get", "indexType"], MIDDLE_INDEX],
            ["literal", [0, 1.2]],
            // else
            ["literal", [0, 0]]
        ]
    }
});
