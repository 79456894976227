import { DataDrivenPropertyValueSpecification } from "maplibre-gl";
import layerBuilder from "./layerBuilder";
import { MAP_FONT } from "./commonProps";

export const FOREGROUND_COLOR = "#3f9cd9";

// SDK type issues with expressions require casts:
const extraForegroundLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    2,
    5,
    3,
    10,
    4,
    18,
    6
] as DataDrivenPropertyValueSpecification<number>;
const foregroundLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    3,
    5,
    4,
    10,
    7,
    18,
    10
] as DataDrivenPropertyValueSpecification<number>;
const backgroundLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    6,
    5,
    7,
    10,
    11,
    18,
    15
] as DataDrivenPropertyValueSpecification<number>;
const extraBackgroundLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    9,
    5,
    11,
    10,
    15,
    18,
    20
] as DataDrivenPropertyValueSpecification<number>;
const altBackgroundLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    4,
    5,
    5,
    10,
    9,
    18,
    13
] as DataDrivenPropertyValueSpecification<number>;
const pathSelectionLineWidth = [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    18,
    5,
    20,
    10,
    32,
    18,
    50
] as DataDrivenPropertyValueSpecification<number>;

export const planRouteForegroundLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-color": FOREGROUND_COLOR,
        "line-width": foregroundLineWidth
    }
});

export const planRouteBackgroundLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-color": "#FFFFFF",
        "line-width": backgroundLineWidth
    }
});

export const planRouteAltBgLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-color": "#3F4A55",
        "line-width": altBackgroundLineWidth
    }
});

export const planRouteAltFgLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-color": "#e0e2e3",
        "line-width": foregroundLineWidth
    }
});

export const planRouteTunnelsLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round"
    },
    paint: {
        "line-width": foregroundLineWidth,
        "line-color": "#000000",
        "line-opacity": 0.3
    }
});

export const planRouteFerriesLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-width": foregroundLineWidth,
        "line-color": "#6dc4ed"
    }
});

export const planRouteFerriesPointSymbol = layerBuilder({
    type: "symbol",
    minzoom: 6,
    // zoom where the map POI naturally appears:
    maxzoom: 16.5,
    layout: {
        "symbol-placement": "point",
        "symbol-avoid-edges": true,
        "icon-image": "145",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 6, 0.8, 16.5, 1],
        // helps smooth the transition from along-route to map-poi, which also has a label in it:
        "icon-ignore-placement": true
    }
});

export const planRouteTollRoadsBackgroundLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-width": extraBackgroundLineWidth,
        "line-color": "#BEBFFA"
    }
});

export const planRouteTollRoadsPointSymbol = layerBuilder({
    type: "symbol",
    minzoom: 4,
    layout: {
        "symbol-placement": "point",
        "symbol-avoid-edges": true,
        "icon-image": "284",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 4, 0.8, 16.5, 1]
    }
});

export const planRouteIncidentsBGLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round"
    },
    paint: {
        "line-width": extraForegroundLineWidth,
        "line-color": [
            "match",
            ["get", "magnitudeOfDelay"],
            "MINOR",
            "#FFC105",
            "MODERATE",
            "#FB2D09",
            "MAJOR",
            "#AD0000",
            // other
            "#C7D2D8"
        ]
    }
});

export const planRouteIncidentsDashedLine = layerBuilder({
    type: "line",
    filter: ["in", "magnitudeOfDelay", "UNKNOWN", "UNDEFINED"],
    layout: {
        "line-join": "round"
    },
    paint: {
        "line-width": extraForegroundLineWidth,
        "line-color": [
            "match",
            ["get", "magnitudeOfDelay"],
            "UNKNOWN",
            "rgba(190, 39, 27, 1)",
            // other (undefined):
            "rgba(137, 150, 168, 1)"
        ],
        "line-dasharray": [1.5, 1]
    }
});

export const planRouteIncidentsPatternLine = layerBuilder({
    type: "line",
    filter: ["in", "magnitudeOfDelay", "MINOR", "MODERATE", "MAJOR"],
    layout: {
        "line-join": "round"
    },
    paint: {
        "line-width": extraForegroundLineWidth,
        "line-pattern": [
            "match",
            ["get", "magnitudeOfDelay"],
            "MINOR",
            "traffic-incidents-slow",
            "MODERATE",
            "traffic-incidents-queueing",
            "MAJOR",
            "traffic-incidents-stationary",
            // other
            "traffic-diagonal-unknown"
        ]
    }
});

export const planRouteIncidentsPointSymbol = layerBuilder({
    type: "symbol",
    minzoom: 6,
    layout: {
        "symbol-placement": "point",
        "symbol-avoid-edges": true,
        "icon-anchor": "bottom",
        "icon-ignore-placement": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 6, 0.8, 12, 1],
        "icon-image": [
            "match",
            [
                "concat",
                [
                    "get",
                    "mainCauseCode",
                    [
                        "at",
                        0, // <-- returns element at index "0" from array "causes"
                        [
                            "get",
                            "causes", // <-- returns the array "causes" from "tec"
                            [
                                "get",
                                "tec" // <-- returns the object "tec" from properties
                            ]
                        ]
                    ]
                ],
                [
                    "case",
                    ["==", ["get", "magnitudeOfDelay"], "MINOR"],
                    1,
                    ["==", ["get", "magnitudeOfDelay"], "MODERATE"],
                    2,
                    ["==", ["get", "magnitudeOfDelay"], "MAJOR"],
                    3,
                    ["==", ["get", "magnitudeOfDelay"], "UNDETERMINED"],
                    4,
                    0
                ]
            ],
            "10",
            "traffic_no_delay_jam",
            "11",
            "traffic_slow_jam",
            "12",
            "traffic_queueing_jam",
            "13",
            "traffic_stationary_jam",
            "14",
            "traffic_no_delay_jam",
            "20",
            "traffic_no_delay_accident",
            "21",
            "traffic_slow_accident",
            "22",
            "traffic_queueing_accident",
            "23",
            "traffic_stationary_accident",
            "24",
            "traffic_no_delay_accident",
            "180",
            "traffic_no_delay_weather_fog",
            "181",
            "traffic_slow_weather_fog",
            "182",
            "traffic_queueing_weather_fog",
            "183",
            "traffic_stationary_weather_fog",
            "184",
            "traffic_no_delay_weather_fog",
            "90",
            "traffic_no_delay_danger",
            "91",
            "traffic_slow_danger",
            "92",
            "traffic_queueing_danger",
            "93",
            "traffic_stationary_danger",
            "94",
            "traffic_no_delay_danger",
            "190",
            "traffic_no_delay_weather_rain",
            "191",
            "traffic_slow_weather_rain",
            "192",
            "traffic_queueing_weather_rain",
            "193",
            "traffic_stationary_weather_rain",
            "194",
            "traffic_no_delay_weather_rain",
            "160",
            "traffic_no_delay_lane_closed",
            "161",
            "traffic_slow_lane_closed",
            "162",
            "traffic_queueing_lane_closed",
            "163",
            "traffic_stationary_lane_closed",
            "164",
            "traffic_no_delay_lane_closed",
            "50",
            "traffic_road_closed",
            "51",
            "traffic_road_closed",
            "52",
            "traffic_road_closed",
            "53",
            "traffic_road_closed",
            "54",
            "traffic_road_closed",
            "30",
            "traffic_no_delay_roadworks",
            "31",
            "traffic_slow_roadworks",
            "32",
            "traffic_queueing_roadworks",
            "33",
            "traffic_stationary_roadworks",
            "34",
            "traffic_no_delay_roadworks",
            "170",
            "traffic_no_delay_weather_wind",
            "171",
            "traffic_slow_weather_wind",
            "172",
            "traffic_queueing_weather_wind",
            "173",
            "traffic_stationary_weather_wind",
            "174",
            "traffic_no_delay_weather_wind",
            ""
        ],
        "text-field": [
            "case",
            // Skipping delays that round to 0 minutes:
            [">", ["get", "delayInSeconds"], 30],
            [
                "concat",
                "+ ",
                ["format", ["to-string", ["round", ["/", ["get", "delayInSeconds"], 60]]], { "text-size": 1 } as any],
                " min"
            ],
            ""
        ],
        "text-font": [MAP_FONT],
        "text-optional": true,
        "text-anchor": "top",
        "text-size": ["interpolate", ["linear"], ["zoom"], 6, 11, 10, 13]
    },
    paint: {
        "text-color": [
            "match",
            ["get", "magnitudeOfDelay"],
            "MINOR",
            "#f58240",
            "MODERATE",
            "#FB2D09",
            "MAJOR",
            "#AD0000",
            "UNDEFINED",
            "#666666",
            //other
            "#000000"
        ],
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 1
    }
});

export const planRouteTimeIntervalsSymbol = layerBuilder({
    type: "symbol",
    minzoom: 4,
    layout: {
        "symbol-placement": "point",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 4, 0.4, 13, 0.5],
        "icon-image": "clock",
        "icon-padding": 0,
        "text-field": ["get", "durationFromStart"],
        "text-size": ["interpolate", ["linear"], ["zoom"], 4, 9, 10, 11],
        "text-font": [MAP_FONT]
    },
    paint: {
        "text-color": ["case", ["get", "foregroundRoute"], FOREGROUND_COLOR, "#737f89"]
    }
});

export const planRouteDistanceIntervalsSymbol = layerBuilder({
    type: "symbol",
    minzoom: 7,
    layout: {
        "symbol-placement": "point",
        "icon-image": "rectangle-white-black-7",
        "icon-padding": 0,
        "icon-text-fit": "both",
        "icon-text-fit-padding": [1, 2, 1, 2],
        "text-field": ["get", "lengthFromStart"],
        "text-size": ["interpolate", ["linear"], ["zoom"], 4, 9, 10, 11],
        "text-font": ["Noto-Regular"]
    },
    paint: {
        "text-color": ["case", ["get", "foregroundRoute"], FOREGROUND_COLOR, "#737f89"]
    }
});

export const planRouteCurvesLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-width": extraBackgroundLineWidth,
        "line-color": [
            "interpolate",
            ["linear"],
            ["abs", ["get", "bearingChangeDegrees"]],
            0,
            ["case", ["has", "relatedToManeuvering"], "#eacbb5", "yellow"],
            180,
            ["case", ["has", "relatedToManeuvering"], "#97572b", "red"]
        ] as DataDrivenPropertyValueSpecification<string>
    }
});

export const planRouteCurvyStretchesLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-width": extraBackgroundLineWidth,
        "line-color": ["case", ["has", "mostlyManeuvering"], "#C4A484", "#FFBF00"],
        "line-dasharray": [0, 1]
    }
});

export const planRouteSelectionLine = layerBuilder({
    type: "line",
    layout: {
        "line-join": "round",
        "line-cap": "round"
    },
    paint: {
        "line-color": "#abe2fb",
        "line-width": pathSelectionLineWidth
    }
});
