import { commonTextSize, SELECTED_STATE, STATE_PROP } from "./commonProps";
import layerBuilder from "./layerBuilder";

export const myPlacesTextLayer = layerBuilder({
    type: "symbol",
    layout: {
        "text-field": ["get", "title"],
        "text-anchor": "top",
        "text-offset": [0, 0.5],
        "text-font": ["Noto-Bold"],
        "text-size": commonTextSize,
        "text-padding": 5
    },
    paint: {
        "text-color": ["case", ["==", ["get", STATE_PROP], SELECTED_STATE], "#3788BD", "#333333"],
        "text-halo-color": "#FFFFFF",
        "text-halo-width": ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 1.5],
        "text-translate-anchor": "viewport"
    }
});

export const myPlacesIconLayer = layerBuilder({
    type: "symbol",
    layout: {
        "icon-image": [
            "case",
            ["==", ["get", STATE_PROP], SELECTED_STATE],
            "favourites_pin_selected",
            "favourites_pin"
        ],
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-padding": 0,
        "icon-size": 0.425
    },
    paint: {
        "icon-translate": [0, 5],
        "icon-translate-anchor": "viewport"
    }
});
