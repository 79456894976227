import { FilterSpecification } from "maplibre-gl";

export const buildIconFilterExpression = (icon: number): FilterSpecification => ["==", ["get", "icon"], icon];
export const buildIconArrayFilterExpression = (icons: number[]): FilterSpecification => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const expression: any = ["any"];
    icons?.forEach((icon) => expression.push(buildIconFilterExpression(icon)));
    return expression;
};
export const buildExcludeIconFilterExpression = (icon: number) => ["!=", ["get", "icon"], icon];
export const buildExcludeIconArrayFilterExpression = (icons: number[]) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const expression: any = ["all"];
    icons?.forEach((icon) => expression.push(buildExcludeIconFilterExpression(icon)));
    return expression;
};

/**
 * Builds a replica of the map POI layer which we can add on top with a POI category filter, thus prioritizing such category on the map.
 */
export const buildBoostedPOICategoryLayer = () => ({
    id: "boostedPOIs",
    layout: {
        "icon-image": "{icon}",
        "icon-padding": 5,
        "icon-size": {
            stops: [
                [10, 0.7],
                [18, 1]
            ]
        },
        "symbol-placement": "point",
        "symbol-sort-key": ["get", "priority"],
        "symbol-z-order": "auto",
        "text-field": "{name}\n{sub_text}",
        "text-font": ["Noto-Regular"],
        "text-justify": "auto",
        "text-letter-spacing": 0.1,
        "text-line-height": 1.1,
        "text-radial-offset": {
            stops: [
                [10, 1],
                [18, 1.3]
            ]
        },
        "text-size": [
            "step",
            ["zoom"],
            ["/", 16, ["log10", ["max", ["length", ["get", "name"]], 30]]],
            15,
            ["/", 17, ["log10", ["max", ["length", ["get", "name"]], 30]]],
            18,
            ["/", 18, ["log10", ["max", ["length", ["get", "name"]], 30]]]
        ],
        "text-variable-anchor": ["top", "left", "bottom", "right"]
    },
    metadata: "group:extra_POI",
    paint: {
        "text-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            [
                "match",
                ["get", "category"],
                "automotive_dealer",
                "hsl(212, 58%, 39%)",
                "car_wash",
                "hsl(212, 58%, 39%)",
                "electric_vehicle_station",
                "hsl(212, 58%, 39%)",
                "motoring_organization_office",
                "hsl(212, 58%, 39%)",
                "open_parking_area",
                "hsl(203, 87%, 48%)",
                "parking_garage",
                "hsl(203, 87%, 48%)",
                "gas_station",
                "hsl(212, 58%, 39%)",
                "rent_a_car_facility",
                "hsl(291, 29%, 48%)",
                "rent_a_car_parking",
                "hsl(203, 87%, 48%)",
                "repair_facility",
                "hsl(291, 29%, 48%)",
                "rest_area",
                "hsl(160, 43%, 30%)",
                "toll_gate",
                "hsl(212, 58%, 39%)",
                "truck_stop",
                "hsl(212, 58%, 39%)",
                "weigh_station",
                "hsl(212, 58%, 39%)",
                "agriculture_business",
                "hsl(237, 14%, 39%)",
                "bank",
                "hsl(324, 8%, 42%)",
                "business_park",
                "hsl(291, 29%, 48%)",
                "atm",
                "hsl(291, 29%, 48%)",
                "commercial_building",
                "hsl(291, 29%, 48%)",
                "company",
                "hsl(291, 29%, 48%)",
                "emergency_medical_service",
                "hsl(8, 63%, 36%)",
                "exchange",
                "hsl(291, 29%, 48%)",
                "exhibition_and_convention_center",
                "hsl(324, 35%, 40%)",
                "industrial_building",
                "hsl(237, 14%, 39%)",
                "manufacturing_facility",
                "hsl(237, 14%, 39%)",
                "media_facility",
                "hsl(291, 29%, 48%)",
                "research_facility",
                "hsl(324, 8%, 42%)",
                "cafe_or_pub",
                "hsl(25, 83%, 53%)",
                "restaurant",
                "hsl(25, 83%, 53%)",
                "restaurant_area",
                "hsl(25, 83%, 53%)",
                "college_or_university",
                "hsl(31, 38%, 34%)",
                "courthouse",
                "hsl(324, 8%, 42%)",
                "embassy",
                "hsl(324, 8%, 42%)",
                "fire_station_or_brigade",
                "hsl(324, 8%, 42%)",
                "government_office",
                "hsl(324, 8%, 42%)",
                "library",
                "hsl(324, 8%, 42%)",
                "military_installation",
                "hsl(324, 8%, 42%)",
                "native_reservation",
                "hsl(160, 43%, 30%)",
                "non_governmental_organization",
                "hsl(324, 8%, 42%)",
                "police_station",
                "hsl(324, 8%, 42%)",
                "post_office",
                "hsl(291, 29%, 48%)",
                "primary_resource_or_utility",
                "hsl(0, 0%, 54%)",
                "prison_or_correctional_facility",
                "hsl(324, 8%, 42%)",
                "public_amenity",
                "hsl(324, 8%, 42%)",
                "school",
                "hsl(31, 38%, 34%)",
                "traffic_service_center",
                "hsl(291, 29%, 48%)",
                "transport_authority_or_vehicle_registration",
                "hsl(324, 8%, 42%)",
                "welfare_organization",
                "hsl(8, 63%, 36%)",
                "dentist",
                "hsl(8, 63%, 36%)",
                "doctor",
                "hsl(8, 63%, 36%)",
                "emergency_room",
                "hsl(8, 63%, 36%)",
                "health_care_service",
                "hsl(8, 63%, 36%)",
                "hospital",
                "hsl(8, 63%, 36%)",
                "pharmacy",
                "hsl(8, 63%, 36%)",
                "veterinarian",
                "hsl(8, 63%, 36%)",
                "amusement_park",
                "hsl(324, 35%, 40%)",
                "casino",
                "hsl(291, 29%, 48%)",
                "movie_theater",
                "hsl(324, 35%, 40%)",
                "club_and_association",
                "hsl(324, 35%, 40%)",
                "community_center",
                "hsl(324, 8%, 42%)",
                "cultural_center",
                "hsl(324, 35%, 40%)",
                "entertainment",
                "hsl(324, 35%, 40%)",
                "leisure_center",
                "hsl(324, 35%, 40%)",
                "marina",
                "hsl(160, 43%, 30%)",
                "museum",
                "hsl(324, 35%, 40%)",
                "nightlife",
                "hsl(324, 35%, 40%)",
                "park_and_recreation_area",
                "hsl(160, 43%, 30%)",
                "theater",
                "hsl(324, 35%, 40%)",
                "trail_system",
                "hsl(160, 43%, 30%)",
                "winery",
                "hsl(25, 83%, 53%)",
                "zoo_arboreta_and_botanical_garden",
                "hsl(324, 35%, 40%)",
                "camping_ground",
                "hsl(246, 46%, 53%)",
                "vacation_rental",
                "hsl(246, 46%, 53%)",
                "hotel_or_motel",
                "hsl(246, 46%, 53%)",
                "residential_accommodations",
                "hsl(0, 0%, 54%)",
                "department_store",
                "hsl(291, 29%, 48%)",
                "market",
                "hsl(291, 29%, 48%)",
                "shop",
                "hsl(291, 29%, 48%)",
                "shopping_center",
                "hsl(291, 29%, 48%)",
                "golf_course",
                "hsl(160, 43%, 30%)",
                "ice_skating_rink",
                "hsl(160, 43%, 30%)",
                "sports_center",
                "hsl(160, 43%, 30%)",
                "stadium",
                "hsl(324, 35%, 40%)",
                "swimming_pool",
                "hsl(324, 35%, 40%)",
                "tennis_court",
                "hsl(160, 43%, 30%)",
                "water_sport",
                "hsl(160, 43%, 30%)",
                "adventure_sports_venue",
                "hsl(160, 43%, 30%)",
                "beach",
                "hsl(160, 43%, 30%)",
                "tourist_attraction",
                "hsl(160, 43%, 30%)",
                "place_of_worship",
                "hsl(324, 8%, 42%)",
                "scenic_or_panoramic_view",
                "hsl(160, 43%, 30%)",
                "tourist_information_office",
                "hsl(237, 14%, 39%)",
                "access_gateway",
                "hsl(212, 58%, 39%)",
                "airport",
                "hsl(212, 58%, 39%)",
                "ferry_terminal",
                "hsl(212, 58%, 39%)",
                "frontier_crossing",
                "hsl(212, 58%, 39%)",
                "helipad",
                "hsl(212, 58%, 39%)",
                "mountain_pass",
                "hsl(160, 43%, 30%)",
                "port_or_warehouse_facility",
                "hsl(291, 29%, 48%)",
                "public_transportation_stop",
                "hsl(212, 58%, 39%)",
                "railroad_station",
                "hsl(212, 58%, 39%)",
                "geographic_feature",
                "hsl(160, 43%, 30%)",
                "courier_drop_box",
                "hsl(291, 50%, 70%)",
                "checkpoint",
                "hsl(326, 13%, 68%)",
                "hsl(210, 40%, 45%)"
            ],
            [
                "match",
                ["get", "category"],
                "automotive_dealer",
                "hsl(212, 58%, 32%)",
                "car_wash",
                "hsl(212, 58%, 32%)",
                "electric_vehicle_station",
                "hsl(212, 58%, 32%)",
                "motoring_organization_office",
                "hsl(212, 58%, 32%)",
                "open_parking_area",
                "hsl(203, 87%, 41%)",
                "parking_garage",
                "hsl(203, 87%, 41%)",
                "gas_station",
                "hsl(212, 58%, 32%)",
                "rent_a_car_facility",
                "hsl(291, 29%, 41%)",
                "rent_a_car_parking",
                "hsl(203, 87%, 41%)",
                "repair_facility",
                "hsl(291, 29%, 41%)",
                "rest_area",
                "hsl(160, 43%, 23%)",
                "toll_gate",
                "hsl(212, 58%, 32%)",
                "truck_stop",
                "hsl(212, 58%, 32%)",
                "weigh_station",
                "hsl(212, 58%, 32%)",
                "agriculture_business",
                "hsl(237, 14%, 32%)",
                "bank",
                "hsl(324, 8%, 35%)",
                "business_park",
                "hsl(291, 29%, 41%)",
                "atm",
                "hsl(291, 29%, 41%)",
                "commercial_building",
                "hsl(291, 29%, 41%)",
                "company",
                "hsl(291, 29%, 41%)",
                "emergency_medical_service",
                "hsl(8, 63%, 29%)",
                "exchange",
                "hsl(291, 29%, 41%)",
                "exhibition_and_convention_center",
                "hsl(324, 35%, 33%)",
                "industrial_building",
                "hsl(237, 14%, 32%)",
                "manufacturing_facility",
                "hsl(237, 14%, 32%)",
                "media_facility",
                "hsl(291, 29%, 41%)",
                "research_facility",
                "hsl(324, 8%, 35%)",
                "cafe_or_pub",
                "hsl(25, 83%, 44%)",
                "restaurant",
                "hsl(25, 83%, 44%)",
                "restaurant_area",
                "hsl(25, 83%, 44%)",
                "college_or_university",
                "hsl(31, 38%, 27%)",
                "courthouse",
                "hsl(324, 8%, 35%)",
                "embassy",
                "hsl(324, 8%, 35%)",
                "fire_station_or_brigade",
                "hsl(324, 8%, 35%)",
                "government_office",
                "hsl(324, 8%, 35%)",
                "library",
                "hsl(324, 8%, 35%)",
                "military_installation",
                "hsl(324, 8%, 35%)",
                "native_reservation",
                "hsl(160, 43%, 23%)",
                "non_governmental_organization",
                "hsl(324, 8%, 35%)",
                "police_station",
                "hsl(324, 8%, 35%)",
                "post_office",
                "hsl(291, 29%, 41%)",
                "primary_resource_or_utility",
                "hsl(0, 0%, 47%)",
                "prison_or_correctional_facility",
                "hsl(324, 8%, 35%)",
                "public_amenity",
                "hsl(324, 8%, 35%)",
                "school",
                "hsl(31, 38%, 27%)",
                "traffic_service_center",
                "hsl(291, 29%, 41%)",
                "transport_authority_or_vehicle_registration",
                "hsl(324, 8%, 35%)",
                "welfare_organization",
                "hsl(8, 63%, 29%)",
                "dentist",
                "hsl(8, 63%, 29%)",
                "doctor",
                "hsl(8, 63%, 29%)",
                "emergency_room",
                "hsl(8, 63%, 29%)",
                "health_care_service",
                "hsl(8, 63%, 29%)",
                "hospital",
                "hsl(8, 63%, 29%)",
                "pharmacy",
                "hsl(8, 63%, 29%)",
                "veterinarian",
                "hsl(8, 63%, 29%)",
                "amusement_park",
                "hsl(324, 35%, 33%)",
                "casino",
                "hsl(291, 29%, 41%)",
                "movie_theater",
                "hsl(324, 35%, 33%)",
                "club_and_association",
                "hsl(324, 35%, 33%)",
                "community_center",
                "hsl(324, 8%, 35%)",
                "cultural_center",
                "hsl(324, 35%, 33%)",
                "entertainment",
                "hsl(324, 35%, 33%)",
                "leisure_center",
                "hsl(324, 35%, 33%)",
                "marina",
                "hsl(160, 43%, 23%)",
                "museum",
                "hsl(324, 35%, 33%)",
                "nightlife",
                "hsl(324, 35%, 33%)",
                "park_and_recreation_area",
                "hsl(160, 43%, 23%)",
                "theater",
                "hsl(324, 35%, 33%)",
                "trail_system",
                "hsl(160, 43%, 23%)",
                "winery",
                "hsl(25, 83%, 44%)",
                "zoo_arboreta_and_botanical_garden",
                "hsl(324, 35%, 33%)",
                "camping_ground",
                "hsl(246, 46%, 44%)",
                "vacation_rental",
                "hsl(246, 46%, 44%)",
                "hotel_or_motel",
                "hsl(246, 46%, 44%)",
                "residential_accommodations",
                "hsl(0, 0%, 47%)",
                "department_store",
                "hsl(291, 29%, 41%)",
                "market",
                "hsl(291, 29%, 41%)",
                "shop",
                "hsl(291, 29%, 41%)",
                "shopping_center",
                "hsl(291, 29%, 41%)",
                "golf_course",
                "hsl(160, 43%, 23%)",
                "ice_skating_rink",
                "hsl(160, 43%, 23%)",
                "sports_center",
                "hsl(160, 43%, 23%)",
                "stadium",
                "hsl(324, 35%, 33%)",
                "swimming_pool",
                "hsl(324, 35%, 33%)",
                "tennis_court",
                "hsl(160, 43%, 23%)",
                "water_sport",
                "hsl(160, 43%, 23%)",
                "adventure_sports_venue",
                "hsl(160, 43%, 23%)",
                "beach",
                "hsl(160, 43%, 23%)",
                "tourist_attraction",
                "hsl(160, 43%, 23%)",
                "place_of_worship",
                "hsl(324, 8%, 35%)",
                "scenic_or_panoramic_view",
                "hsl(160, 43%, 23%)",
                "tourist_information_office",
                "hsl(237, 14%, 32%)",
                "access_gateway",
                "hsl(212, 58%, 32%)",
                "airport",
                "hsl(212, 58%, 32%)",
                "ferry_terminal",
                "hsl(212, 58%, 32%)",
                "frontier_crossing",
                "hsl(212, 58%, 32%)",
                "helipad",
                "hsl(212, 58%, 32%)",
                "mountain_pass",
                "hsl(160, 43%, 23%)",
                "port_or_warehouse_facility",
                "hsl(291, 29%, 41%)",
                "public_transportation_stop",
                "hsl(212, 58%, 32%)",
                "railroad_station",
                "hsl(212, 58%, 32%)",
                "geographic_feature",
                "hsl(160, 43%, 23%)",
                "courier_drop_box",
                "hsl(291, 50%, 63%)",
                "checkpoint",
                "hsl(326, 13%, 61%)",
                "hsl(0, 0%, 0%)"
            ]
        ],
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 1
    },
    source: "poiTiles",
    "source-layer": "poi_extended",
    type: "symbol"
});
