import layerBuilder from "./layerBuilder";

const color = "#3f9cd9";

export const reachableRangeFill = layerBuilder({
    type: "fill",
    paint: {
        "fill-color": color,
        "fill-opacity": 0.25,
        "fill-antialias": false
    }
});

export const reachableRangeOutline = layerBuilder({
    type: "line",
    paint: {
        "line-color": color,
        "line-opacity": 0.6,
        "line-width": 2
    }
});
