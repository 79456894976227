import { DataDrivenPropertyValueSpecification } from "maplibre-gl";

export const STATE_PROP = "state";
export const HOVERED_STATE = "hovered";
export const SELECTED_STATE = "selected";
export const BACKGROUND_STATE = "background";
export const MAP_FONT = "Noto-Bold";

export const commonTextSize = [
    "interpolate",
    ["linear"],
    ["zoom"],
    10,
    12,
    16,
    14
] as DataDrivenPropertyValueSpecification<number>;
export const defaultIconSize = [
    "interpolate",
    ["linear"],
    ["zoom"],
    10,
    0.7,
    16,
    0.85
] as DataDrivenPropertyValueSpecification<number>;
