import { LocationProvider, Point as GORPoint } from "@anw/gor-sdk";
import { SearchIntention } from "../../state/tree/map-page/search/types";
import { TTMSearchResult } from "../../utils/locationTypes";
import { removeEmptyParameters } from "../../utils/objects";
import { DiscoverResult, isSearchType, ResultType, SuggestResult } from "./ngsAPITypes";

export const toTTMSearchResult = (suggestResult: SuggestResult): TTMSearchResult => {
    if (!suggestResult) {
        return undefined;
    }

    const mappedResult = {
        // GOR-type parts:
        formattedAddress: suggestResult.description,
        poiName: suggestResult.type === ResultType.POI ? suggestResult.name : undefined,
        externalID: suggestResult.id,
        point: undefined,
        provider: "TOM_TOM" as LocationProvider,
        // TODO: the types of search results do not match - unification needed
        type: undefined,
        poiCategory: suggestResult?.categories?.find(() => true)?.id?.toString(),
        poiSubcategories: undefined,
        poiCategorySetIDs: suggestResult?.categories?.map((cat) => cat.id),
        poiBrandNames: undefined,
        street: undefined,
        houseNumber: undefined,
        postCode: undefined,
        city: undefined,
        countrySubdivision: undefined,
        countryISO3: undefined,
        boundingBox: undefined,
        poiPhone: undefined,
        poiUrl: undefined,
        entryPoints: undefined,
        municipality: undefined,
        municipalitySubdivision: undefined,
        countrySecondarySubdivision: undefined,
        countryTertiarySubdivision: undefined,
        extendedPostalCode: undefined,
        countrySubdivisionName: undefined,
        geometryId: undefined,
        geographyEntityType: undefined,
        // TTM-only location-info parts:
        localizedCountryName: undefined,
        localizedPOIClassificationNames: undefined,
        openingHours: undefined,
        timeZone: undefined,
        ngsType: suggestResult.type,
        name: suggestResult.name,
        description: suggestResult.description,
        link: suggestResult.more.discover
            ? suggestResult.more.discover
            : suggestResult.more.details
            ? suggestResult.more.details
            : undefined,
        intention: isSearchType(suggestResult.type) ? SearchIntention.RECOVERY : SearchIntention.DISCOVERY
    };
    removeEmptyParameters(mappedResult);
    return mappedResult;
};

const discoverToTTMSearchResult = (discoverResult: DiscoverResult): TTMSearchResult => {
    if (!discoverResult) {
        return undefined;
    }

    const mappedResult = {
        // GOR-type parts:
        formattedAddress: discoverResult.description,
        poiName: discoverResult.name,
        externalID: discoverResult.id,
        point: [discoverResult.position.lat, discoverResult.position.lon] as GORPoint,
        provider: "TOM_TOM" as LocationProvider,
        // TODO: the types of search results do not match - unification needed
        type: undefined,
        poiCategory: discoverResult?.categories?.[0].code,
        poiCategorySetIDs: discoverResult?.categories?.map((cat) => cat.id),
        localizedPOIClassificationNames: discoverResult?.categories?.[0].names.map((name) => name.name),
        poiSubcategories: undefined,
        poiBrandNames: undefined,
        street: undefined,
        houseNumber: undefined,
        postCode: undefined,
        city: undefined,
        countrySubdivision: undefined,
        countryISO3: undefined,
        boundingBox: undefined,
        poiPhone: undefined,
        poiUrl: undefined,
        entryPoints: undefined,
        municipality: undefined,
        municipalitySubdivision: undefined,
        countrySecondarySubdivision: undefined,
        countryTertiarySubdivision: undefined,
        extendedPostalCode: undefined,
        countrySubdivisionName: undefined,
        geometryId: undefined,
        geographyEntityType: undefined,
        // TTM-only location-info parts:
        localizedCountryName: undefined,
        openingHours: undefined,
        timeZone: undefined,
        ngsType: ResultType.POI,
        name: discoverResult.name,
        description: discoverResult.description,
        link: discoverResult.more.discover
            ? discoverResult.more.discover
            : discoverResult.more.details
            ? discoverResult.more.details
            : undefined,
        intention: SearchIntention.RECOVERY
    };
    removeEmptyParameters(mappedResult);
    return mappedResult;
};

export const mapToTTMSearchResults = (suggestResults: SuggestResult[]): TTMSearchResult[] => {
    return suggestResults?.map(toTTMSearchResult);
};

export const mapDiscoverToTTMSearchResults = (discoverResults: DiscoverResult[]): TTMSearchResult[] => {
    return discoverResults?.map(discoverToTTMSearchResult);
};
